<template>
  <div class="flex text-gray-400 text-xl font-bold">
    <div
      v-for="item in props.menuItemList"
      :key="item.path"
      class="flex items-end"
    >
      <nuxt-link
        :to="item.path"
        class="h-full px-4 flex items-center justify-center"
        :class="props.type === 'firstMenu' ? 'first_nuxt_link' : 'second_nuxt_link'"
        @click="onNavigateTo"
      >
        <SvgIcon
          :icon-class="item.icon"
          :size="item.icon ? '25px' : '0'"
        />
        {{ item.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  menuItemList: nuxtLinkMenuItem[],
  type: string,
}>(), {
  menuItemList: () => [],
  type: 'firstMenu'
})
const emits = defineEmits<{(event: 'onNavigateTo'): void}>()

function onNavigateTo() {
  emits('onNavigateTo')
}
</script>

<style lang="scss" scoped>
.first_nuxt_link {
  width: 180px;
  height: 44px !important;
  font-size: 20px;
  font-weight: bold;
  color: theme('colors.assist1');
  line-height: 35px;
  &.router-link-active {
    background-image: url('~/assets/img/tab_active.png');
    color: #FFFFFF;
  }
}
.second_nuxt_link {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 16px;
  &.router-link-active {
    border-bottom: 3px solid #fff;
  }
}
</style>

